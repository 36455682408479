import uiConf_json from '../../ui-conf.json';
import {DEFAULT_UI_CONFIGURATION, SsoUIConfiguration, SsoUIField, SsoUILocaleSwitcherLocale} from './ssoui-configuration';
import {Inject, Injectable} from '@angular/core';
import {AuthenticationState} from '../app-state.service';
import {BehaviorSubject, Observable} from 'rxjs';
import {mergeInDefaults, migrateConfToLatest} from './configuration-tools';

const cloneDeep = require('lodash.clonedeep');
const lodash = require('lodash');

const merge = lodash.mergeWith;
/**
 * Customizes merging of arrays by concatenating arrays and removing duplicates
 * @param objValue Obj to merge
 * @param srcValue Obj to merge with
 * @return Merged array or undefined
 */
const mergeCustomizer = function(objValue, srcValue): any[]|undefined {
  if (lodash.isArray(objValue)) {
    const arr = objValue.concat(srcValue);
    return arr.filter((val, ind) => {
      return arr.indexOf(val) === ind;
    });
  }
};

/**
 * Holder object for the field configuration
 */
const FIELD_CONFIG: any = {
  PROFILE_SUPPORTED_PERSONAL_FIELDS: [],
  PROFILE_REQUIRED_PERSONAL_FIELDS: [],
  PROFILE_SUPPORTED_CONTACT_FIELDS: [],
  PROFILE_REQUIRED_CONTACT_FIELDS: [],
  PROFILE_SUPPORTED_LOGIN_FIELDS: [],
  PROFILE_REQUIRED_LOGIN_FIELDS: [],
  REGISTRATION_SUPPORTED_FIELDS: [],
  REGISTRATION_REQUIRED_FIELDS: [],
};
/**
 * Singleton api description object
 */
let openAPI: any;

/**
 * Resolves a schema reference as object from the openAPI
 * @param ref Reference string
 * @returns Resolved object
 */
function resolveReference(ref: string): any {
  const pathToObj = ref.split('/');
  let refObj: any = openAPI;
  for (let j = 1; j < pathToObj.length; j += 1) {
    refObj = refObj[pathToObj[j]];
  }
  return resolveObject(refObj);
}

/**
 * Resolves the schema description as object according to the description
 * @param obj The Schema to resolve
 * @returns Resolved object
 */
function resolveObject(obj: any): any {
  let object = cloneDeep(obj);
  if (object.$ref) {
    const tmp = object.$ref;
    delete object.$ref;
    object = resolveReference(tmp);
  } else {
    if (object.allOf) {
      const tmp = cloneDeep(object.allOf);
      delete object.allOf;
      for (let i = 0; i < tmp.length; i += 1) {
        object = merge(object, resolveObject(tmp[i]), mergeCustomizer);
      }
    }
    if (object.type === 'object') {
      if (!object.properties) {
        object.properties = {};
      }
      const props = Object.keys(object.properties);
      object.supported = [];
      for (let i = 0; i < props.length; i += 1) {
        object.properties[props[i]] = resolveObject(object.properties[props[i]]);
        object.supported.push(props[i]);
      }
    }
    if (object.type === 'object' && object.supported) {
      object.supported = object.supported.reduce(function(result, value) {
        if (object.properties[value].type === 'object') {
          for (let i = 0; i < object.properties[value].supported.length; i += 1) {
            result.push(value + '-' + object.properties[value].supported[i]);
          }
        } else {
          result.push(value);
        }
        return result;
      }, []);
    }
    if (object.type === 'object' && object.required) {
      object.required = object.required.reduce(function(result, value) {
        if (object.properties[value].type === 'object') {
          for (let i = 0; i < object.properties[value].required.length; i += 1) {
            result.push(value + '-' + object.properties[value].required[i]);
          }
        } else {
          result.push(value);
        }
        return result;
      }, []);
    }
  }
  return object;
}


/**
 * Filter function for identifying personal fields from all profile fields
 * @param val List value
 * @returns true for personal fields, false for others
 */
function isPersonalField(val: string): boolean {
  return ['firstName', 'lastName', 'nickname', 'preferredUsername', 'professionalTitle'].indexOf(val) >= 0;
}

/**
 * Filter function for identifying contact fields from all profile fields
 * @param val List value
 * @returns true for contact fields, false for others
 */
function isContactField(val: string): boolean {

  return val.startsWith('address-') || val === 'phoneNumber';
}

/**
 * Filter function for identifying login fields from all profile fields
 * @param val List value
 * @returns true for login fields, false for others
 */
function isLoginField(val: string): boolean {
  return ['email', 'password', 'totp'].indexOf(val) >= 0;
}

/**
 * Resolves authentcication methods recursively from AuthenticationFlows
 * @param obj The flows, single flow or challenge list
 * @returns Array of authentication methods names
 */
const resolveAuthenticationMethods = function(obj: any): { key: string, required?: boolean }[] {
  let result: { key: string, required?: boolean }[] = [];
  if (lodash.isArray(obj)) {
    for (let i = 0; i < obj.length; i += 1) {
      result = result.concat(resolveAuthenticationMethods(obj[i]));
    }
  } else {
    if (obj.authenticationMethod) {
      result.push({ key: obj.authenticationMethod, required: obj.required });
    }
    if (obj.challenges) {
      result = result.concat(resolveAuthenticationMethods(obj.challenges));
    }
  }
  return result;
};

/**
 * Describes the configuration data model. The members with type SsoUIField[] are populated by configured values and APi requirements:
 * Missing required fields are added, unsupported ones removed and required fields configured as optional are made required, an error is
 * shown in console for all of these.
 *
 * Other fields get their values from the provided ui-conf.json or DEFAULT_UI_CONFIGURATION, unless stated otherwise.
 */
export interface ConfigurationModel {
  /**
   * Name of the service/app eg. 10Duke Identity.
   * Resolve value fallback chain includes branding.json as it's more specific than our DEFAULT_UI_CONFIGURATION.
   * (config['service-name'] || branding['service-name'] || DEFAULT_UI_CONFIGURATION['service-name'])
   */
  serviceName: string;

  /**
   * Toggles the visibility of header.
   */
  showHeader: boolean;

  /**
   * Toggles the visibility of sessionPanel.
   */
  showSessionPanel: boolean;

  /**
   * Toggles the visibility of footer.
   */
  showFooter: boolean;

  /**
   * Toggles the visibility of login to continue notification when login is a pre-step for a navigation action.
   */
  showLoginToContinue: boolean;

  /**
   * Toggles the visibility of penning action notification when login is a pre-step for an action.
   */
  showPendingAction: boolean;

  /**
   * Toggles the availability of an input field for email validation code in UI and related instructional copy.
   * Makes sense only if the sent email includes the code for copy paste.
   */
  showEmailValidationCodeInput: boolean;

  /**
   * Toggles the availability of an input field for reset password code in UI and related instructional copy.
   * Makes sense only if the sent email includes the code for copy paste.
   */
  showResetPasswordCodeInput: boolean;

  /**
   * Toggles the availability of password input fields for reset password when no valid code is found.
   */
  showResetPasswordFieldsWithoutCode: boolean;

  /**
   * Toggles the availability of an input field for activate user code in UI and related instructional copy.
   * Makes sense only if the sent email includes the code for copy paste.
   */
  showActivateUserCodeInput: boolean;

  /**
   * List of locales to show for the locale switching tool.
   * Providing an empty list in ui-config.json is ignored, as it makes nop sense to show an empty dropdown.
   */
  supportedLocales: SsoUILocaleSwitcherLocale[];

  /**
   * Defines the locale to use when none is defined
   */
  defaultLocale: string;

  /**
   * Toggles the availability of the locale switching tool.
   */
  showLocaleSwitcher: boolean;

  /**
   * Fields to display under personal-category/tab.
   * `[...profilePersonalFields, ...profileContactFields, ...profileLoginFields]` determines the available user object fields
   * for the whole app. If a field is not found from this set, it is as if it does not exist in data.
   */
  profilePersonalFields: SsoUIField[];

  /**
   * Timeout after which an idle user gets logged out, negative or 0 to disable
   */
  logoutIdleUserTimeout: number;

  /**
   * timout to show warning about coming idle logout, must be less than or equal to logoutIdleUserTimeout or will be
   * autopopulated to same as logoutIdleUserTimeout
   */
  logoutIdleUserWarningTimeout: number;


  /**
   * Fields to display under contact-category/tab
   * `[...profilePersonalFields, ...profileContactFields, ...profileLoginFields]` determines the available user object fields
   * for the whole app. If a field is not found from this set, it is as if it does not exist in data.
   */
  profileContactFields: SsoUIField[];

  /**
   * Fields to display under login-category/tab. Password is added, if it's supported for registration. Totp is added if it's supported
   * by login API.
   * `[...profilePersonalFields, ...profileContactFields, ...profileLoginFields]` determines the available user object fields
   * for the whole app. If a field is not found from this set, it is as if it does not exist in data.
   */
  profileLoginFields: SsoUIField[];

  /**
   * Toggles the automatic sending of email validation message eg. after successful registration.
   */
  sendEmailValidation: boolean;

  /**
   * Controls the ui to display tools required when email validation is required
   */
  requireEmailValidation: boolean;

  /**
   * Fields to display under registration
   */
  registrationFields: SsoUIField[];

  // tslint:disable:max-line-length
  /**
   * Regex used for validating passwords. If none is required by the API, then default pattern `.*` is used
   * eg. Require length of at least 8 characters and at least on of each: lower case letter, upper case letter, digit, special character
   * `/^(?=(?:[^a-zà-öø-þ]*[a-zà-öø-þ]){1,}[^a-zà-öø-þ]*)(?=(?:[^A-ZÀ-ÖØ-Þ]*[A-ZÀ-ÖØ-Þ]){1,}[^A-ZÀ-ÖØ-Þ]*)(?=(?:[^0-9]*[0-9]){1,}[^0-9]*)(?=(?:[a-zà-öø-þA-ZÀ-ÖØ-Þ0-9]*[^a-zà-öø-þA-ZÀ-ÖØ-Þ0-9]){1,}[a-zà-öø-þA-ZÀ-ÖØ-Þ0-9]*).{8,}$/`
   */
  passwordValidation: RegExp;
  // tslint:enable:max-line-length
}

@Injectable({
  providedIn: 'root',
})
export class ConfigurationService {

  public isReady = false;
  private confIsReadySubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(this.isReady);

  private properties: ConfigurationModel;


  constructor(
    @Inject('FetchOpenAPIDescription') private fetchOpenAPIDescription
  ) {}


  /**
   * Returns the actual configuration, or an empty object to avoid having to repeat sanity checks for every use case.
   */
  public getProperties(): ConfigurationModel|any {
    return this.isReady ? this.properties : {};
  }


  /**
   *
   */
  public whenReady(): Observable<boolean> {
    return this.confIsReadySubject;
  }

  /**
   * Initializes the configuration using provided ui-config.json, DEFAULT_UI_CONFIGURATION and openAPI description
   * @param resolveAuthState Wrapper function that allows this conf service to call AppStateService's fetchAuthenticationFlows without
   * injection that would cause circular dependency
   */
  public async initializeConfiguration(resolveAuthState: (cb: (val: AuthenticationState) => void) => void,
                                       uiConf: any = uiConf_json,
                                       api?: any): Promise<void> {
    /*
    if (this.isReady) {
      return;
    }
     */
    // store original version of conf, empty conf = use latest default and it's version
    const originalUIConfVersion: string = !!(uiConf as SsoUIConfiguration).version ? (uiConf as SsoUIConfiguration).version
      : Object.keys(uiConf).length === 0
        ? (DEFAULT_UI_CONFIGURATION.version as string)
        : '0';
    // transform the inputted conf to the latest conf format
    const config: SsoUIConfiguration = migrateConfToLatest(uiConf as SsoUIConfiguration);

    openAPI = api || await this.fetchOpenAPIDescription();
    // Access required schemas
    if (!openAPI.components || !openAPI.components.schemas) {
      throw new Error('Schemas not found from Api description');
    }
    if (!openAPI.components.schemas.User || !openAPI.components.schemas.UserForRegisterUser) {
      throw new Error('User schema not found from Api description');
    }

    const User = resolveObject(openAPI.components.schemas.User);
    const UserForRegisterUser = resolveObject(openAPI.components.schemas.UserForRegisterUser);
    // create lists of required/supported fields.
    FIELD_CONFIG.REGISTRATION_REQUIRED_FIELDS = cloneDeep(
      UserForRegisterUser.required ? UserForRegisterUser.required : [])
    ;
    // add acceptTsAndCs and pwd confirmation field as required if pwd is required
    if (FIELD_CONFIG.REGISTRATION_REQUIRED_FIELDS.indexOf('password') >= 0) {
      FIELD_CONFIG.REGISTRATION_REQUIRED_FIELDS = FIELD_CONFIG.REGISTRATION_REQUIRED_FIELDS
        .concat(['confirmPassword', 'agreements']);
    } else {
      FIELD_CONFIG.REGISTRATION_REQUIRED_FIELDS.push('agreements');
    }
    FIELD_CONFIG.REGISTRATION_SUPPORTED_FIELDS = cloneDeep(
      UserForRegisterUser.supported ? UserForRegisterUser.supported : []);
    // add acceptTsAndCs and pwd confirmation field  as supported if pwd is supported
    if (FIELD_CONFIG.REGISTRATION_SUPPORTED_FIELDS.indexOf('password') >= 0) {
      FIELD_CONFIG.REGISTRATION_SUPPORTED_FIELDS = FIELD_CONFIG.REGISTRATION_SUPPORTED_FIELDS
        .concat(['confirmPassword', 'agreements']);
      if (UserForRegisterUser.properties.password.pattern) {
        FIELD_CONFIG.PASSWORD_VALIDATION = UserForRegisterUser.properties.password.pattern;
      }
    } else {
      FIELD_CONFIG.REGISTRATION_SUPPORTED_FIELDS.push('agreements');
    }

    // Get auth flows and check if totp is supported
    return new Promise<void>((resolve) => {
      resolveAuthState(
        (val: AuthenticationState) => {
          const defaultFlows = val.getAuthenticationFlows();
          const userRequired = cloneDeep(User.required ? User.required : []);
          const userSupported = cloneDeep(User.supported ? User.supported : []);
          const availableAuthenticationMethods = resolveAuthenticationMethods(defaultFlows);
          if (availableAuthenticationMethods.findIndex((v) => v.key === 'totp') >= 0) {
            userSupported.push('totp');
          }
          // add password into supported user fields, if it's supported in registration to enable pwd change
          if (FIELD_CONFIG.REGISTRATION_SUPPORTED_FIELDS.indexOf('password')) {
            userSupported.push('password');
          }
          // Create the categorized field lists from profile fields
          FIELD_CONFIG.PROFILE_REQUIRED_PERSONAL_FIELDS = userRequired.filter(isPersonalField);
          FIELD_CONFIG.PROFILE_SUPPORTED_PERSONAL_FIELDS = userSupported.filter(isPersonalField);

          FIELD_CONFIG.PROFILE_REQUIRED_CONTACT_FIELDS = userRequired.filter(isContactField);
          FIELD_CONFIG.PROFILE_SUPPORTED_CONTACT_FIELDS = userSupported.filter(isContactField);

          FIELD_CONFIG.PROFILE_REQUIRED_LOGIN_FIELDS = userRequired.filter(isLoginField);
          FIELD_CONFIG.PROFILE_SUPPORTED_LOGIN_FIELDS = userSupported.filter(isLoginField);
          // Create the actual field lists to use, ensurong that they include all required and only supported ones.

          // create the actual conf by merging together requested and matching default conf
          const propertiesHolder: any = mergeInDefaults(config, originalUIConfVersion, FIELD_CONFIG);
          const t = availableAuthenticationMethods.find((v) => v.key === 'emailVerification');
          if (t) {
            propertiesHolder.sendEmailValidation = true;
            propertiesHolder.requireEmailValidation = t.required;
          }

          this.properties = propertiesHolder as ConfigurationModel;
          this.isReady = true;
          this.confIsReadySubject.next(this.isReady);
          resolve();
        });
      })
    ;
  }
}
